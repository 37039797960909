import { Helmet } from 'react-helmet-async';
import { useLanguage } from '@/providers/LanguageProvider';

interface SEOProps {
  title?: string;
  description?: string;
  keywords?: string;
  image?: string;
  url?: string;
  type?: string;
}

const SEO = ({ 
  title,
  description,
  keywords,
  image = "/logo/logo-febro_large_background.svg",
  url = "https://luisfebro.com",
  type = "website"
}: SEOProps) => {
  const { language } = useLanguage();

  const defaultMeta = {
    en: {
      title: "Luis Febro - Software Developer",
      description: "Professional portfolio showcasing software engineering work, skills, and services. Expert in web, mobile, and backend development.",
      keywords: "software developer, programmer, web developer, mobile developer, fullstack developer, React, TypeScript, Node.js, Kotlin",
    },
    "pt-BR": {
      title: "Luis Febro - Desenvolvedor de Software",
      description: "Portfólio profissional apresentando trabalhos, habilidades e serviços de engenharia de software. Especialista em desenvolvimento web, mobile e backend.",
      keywords: "desenvolvedor de software, programador, desenvolvedor web, desenvolvedor mobile, desenvolvedor fullstack, React, TypeScript, Node.js, Kotlin",
    },
    es: {
      title: "Luis Febro - Desarrollador de Software",
      description: "Portafolio profesional que muestra trabajos, habilidades y servicios de ingeniería de software. Experto en desarrollo web, móvil y backend.",
      keywords: "desarrollador de software, programador, desarrollador web, desarrollador móvil, desarrollador fullstack, React, TypeScript, Node.js, Kotlin",
    }
  };

  const currentLang = language as keyof typeof defaultMeta;
  // console.log("currentLang: " + currentLang);
  const meta = defaultMeta[currentLang];

  return (
    <Helmet>
      {/* Language Meta Tag */}
      <html lang={language} />
      
      {/* Basic Meta Tags */}
      <title>{title || meta.title}</title>
      <meta name="description" content={description || meta.description} />
      <meta name="keywords" content={keywords || meta.keywords} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content={type} />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title || meta.title} />
      <meta property="og:description" content={description || meta.description} />
      <meta property="og:image" content={image} />
      <meta property="og:locale" content={language} />
      {language === 'pt-BR' && <meta property="og:locale:alternate" content="en_US" />}

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:title" content={title || meta.title} />
      <meta name="twitter:description" content={description || meta.description} />
      <meta name="twitter:image" content={image} />

      {/* Additional SEO Tags */}
      <meta name="robots" content="index, follow" />
      <meta name="language" content={language === 'pt-BR' ? 'Portuguese' : 'English'} />
      <meta name="author" content="Luis Febro" />
      
      {/* Mobile Optimization Tags */}
      <meta name="format-detection" content="telephone=no" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="apple-mobile-web-app-title" content="Luis Febro" />

      {/* Performance Optimization Tags */}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="dns-prefetch" href="https://fonts.googleapis.com" />

      {/* Language Alternates */}
      <link rel="canonical" href={`${url}${language !== 'en' ? `/${language}` : ''}`} />
      <link rel="alternate" hrefLang="en" href={url} />
      <link rel="alternate" hrefLang="pt-BR" href={`${url}/pt-BR`} />
      <link rel="alternate" hrefLang="x-default" href={url} />
    </Helmet>
  );
};

export default SEO; 