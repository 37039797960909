"use client"

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { allTechs } from '@/data/allTechs';
import { useLanguage } from '@/providers/LanguageProvider';
import { useState, useCallback } from 'react';

interface TechNameTooltipProps {
  techName: keyof typeof allTechs;
  className?: string;
}

export function TechNameTooltip({ techName, className }: TechNameTooltipProps) {
  const { language } = useLanguage();
  const [isOpen, setIsOpen] = useState(false);
  const [isTouch, setIsTouch] = useState(false);

  // Separate handlers for touch and click
  const handleTouch = useCallback((event: React.TouchEvent) => {
    setIsTouch(true);
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleClick = useCallback((event: React.MouseEvent) => {
    if (!isTouch) {
      setIsOpen(!isOpen);
    }
  }, [isOpen, isTouch]);

  const techDescription = allTechs[techName]?.[language] || techName;

  return (
    <TooltipProvider>
      <Tooltip delayDuration={0} open={isTouch ? isOpen : undefined}>
        <TooltipTrigger 
          className={className}
          onClick={handleClick}
          onTouchEnd={handleTouch} // Changed from onTouchStart to onTouchEnd
          onMouseEnter={() => !isTouch && setIsOpen(true)}
          onMouseLeave={() => !isTouch && setIsOpen(false)}
        >
          {techName}
        </TooltipTrigger>
        <TooltipContent 
          className="max-w-[300px]"
          onPointerDownOutside={() => setIsOpen(false)}
        >
          <p>{techDescription}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
} 