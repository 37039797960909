import { FileDown } from 'lucide-react';
import { useLanguage } from '@/providers/LanguageProvider';
import { handleDownloadCV } from '@/utils/downloadHelpers';

interface DownloadCVButtonProps {
  className?: string;
  title?: string;
  'aria-label'?: string;
}

const downloadFile = (fileUrl: string, fileName: string) => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const DownloadCVButton = ({ 
  className = '', 
  title,
  'aria-label': ariaLabel 
}: DownloadCVButtonProps) => {
  const { t, language } = useLanguage();

  const getCVPath = () => {
    return language === 'en'
      ? '/docs/cv/luis_febro_cv_en.pdf'
      : '/docs/cv/luis_febro_cv_pt-br.pdf';
  };

  const getFileName = () => {
    return language === 'en'
      ? 'luis_febro_dev_cv_en.pdf'
      : 'luis_febro_dev_cv_pt_br.pdf';
  };

  return (
    <button
      onClick={() => downloadFile(getCVPath(), getFileName())}
      className={`
        flex items-center gap-2 px-4 py-2 
        border border-primary text-primary
        hover:bg-primary/10
        rounded-lg transition-colors
        font-medium
        ${className}
      `}
      aria-label={ariaLabel || t('footer.downloadCV')}
      title={title || t('footer.downloadCV')}
    >
      {t('footer.downloadCV')}
      <FileDown size={20} className="ml-1" />
    </button>
  );
};

export default DownloadCVButton; 