import { motion } from 'framer-motion';
import { useLanguage } from '../providers/LanguageProvider';
import SectionHeader from '../components/shared/SectionHeader';
import { Code2, Palette, Server, Smartphone, Blocks } from 'lucide-react';
import SEO from '../components/shared/SEOHead';

const About = () => {
  const { t } = useLanguage();

  const skills = [
    { name: t('about.skills.frontend'), icon: <Code2 className="w-6 h-6" />, items: ['React', 'TypeScript', 'JavaScript', 'Next.js', 'Vite'] },
    { name: t('about.skills.backend'), icon: <Server className="w-6 h-6" />, items: ['Node.js', 'Express', 'MongoDB', 'PostgreSQL', 'Python'] },
    { name: t('about.skills.mobile'), icon: <Smartphone className="w-6 h-6" />, items: ['Kotlin', 'Java', 'Jetpack Compose', 'Room', 'Dagger Hilt', 'React Native'] },
    { name: t('about.skills.architecture'), icon: <Blocks className="w-6 h-6" />, items: ['MVVM', 'MVC', 'i18n & Android localization'] },
    { name: t('about.skills.uiux'), icon: <Palette className="w-6 h-6" />, items: ['Figma', 'Gimp', 'Inkscape', 'Photoshop'] },
  ];

  const experiences = [
    {
      title: t('about.experience.positions.fullstack.title'),
      company: t('about.experience.positions.fullstack.company'),
      period: t('about.experience.positions.fullstack.period'),
      description: t('about.experience.positions.fullstack.description'),
    },
    {
      title: t('about.experience.positions.mobile.title'),
      company: t('about.experience.positions.mobile.company'),
      period: t('about.experience.positions.mobile.period'),
      description: t('about.experience.positions.mobile.description'),
    },
    {
      title: t('about.experience.positions.freelance.title'),
      company: t('about.experience.positions.freelance.company'),
      period: t('about.experience.positions.freelance.period'),
      description: t('about.experience.positions.freelance.description'),
    },
  ];

  return (
    <div className="py-20">
      <SEO 
        title={t('about.title') + " - Luis Febro | " + t('home.hero.subtitle')}
        description={t('about.description')}
        keywords="software developer experience, programming skills, web development, mobile development, fullstack developer, React, TypeScript, Kotlin"
      />

      <SectionHeader
        title={t('about.title')}
        subtitle={t('about.description')}
      />

      {/* Bio Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mb-20"
      >
        <div className="flex flex-col items-center gap-12">
          <div className="relative w-25">
            <div className="aspect-square rounded-2xl overflow-hidden">
              <img
                src="/profile.jpg"
                alt={t('about.title')}
                className="object-cover w-full h-full"
              />
            </div>
          </div>
          <div className="space-y-6 max-w-2xl text-center">
            <h2 className="text-2xl font-bold">{t('about.skills.title')}</h2>
            <p className="text-gray-600 dark:text-gray-400">
              {t('about.bio.part1')}
            </p>
            <p className="text-gray-600 dark:text-gray-400">
              {t('about.bio.part2')}
            </p>
          </div>
        </div>
      </motion.div>

      {/* Skills Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mb-20"
      >
        <h2 className="text-2xl font-bold mb-8 text-center">{t('about.technologies')}</h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
          {skills.map((skill, index) => (
            <motion.div 
              key={skill.name}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="p-6 rounded-xl border border-gray-200 dark:border-gray-800 hover:border-primary dark:hover:border-primary transition-colors"
            >
              <div className="flex items-center gap-3 mb-4">
                {skill.icon}
                <h3 className="font-semibold">{skill.name}</h3>
              </div>
              <ul className="space-y-2">
                {skill.items.map((item) => (
                  <li key={item} className="text-gray-600 dark:text-gray-400 flex items-center gap-2">
                    <span className="w-4 h-4 text-primary">•</span>
                    {item}
                  </li>
                ))}
              </ul>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default About; 

/* 
<motion.div
initial={{ opacity: 0, y: 20 }}
animate={{ opacity: 1, y: 0 }}
>
<h2 className="text-2xl font-bold mb-8 text-center">{t('about.experience.title')}</h2>
<div className="space-y-8">
  {experiences.map((exp, index) => (
    <motion.div
      key={exp.title}
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ delay: index * 0.1 }}
      className="flex flex-col md:flex-row gap-4 p-6 rounded-xl border border-gray-200 dark:border-gray-800"
    >
      <div className="md:w-1/4">
        <h3 className="font-semibold">{exp.title}</h3>
        <p className="text-primary">{exp.company}</p>
        <p className="text-sm text-gray-600 dark:text-gray-400">{exp.period}</p>
      </div>
      <div className="md:w-3/4">
        <p className="text-gray-600 dark:text-gray-400">{exp.description}</p>
      </div>
    </motion.div>
  ))}
</div>
</motion.div>

*/