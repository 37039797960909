import axios from 'axios';
import { API_ENDPOINTS } from '@/constants/config';

interface ContactFormData {
  name: string;
  email: string;
  message: string;
}

export const contactApi = {
  sendMessage: async (formData: ContactFormData) => {
    return axios.post(API_ENDPOINTS.CONTACT, formData);
  }
}; 