export const ptBR = {
  nav: {
    home: "Início",
    about: "Sobre",
    portfolio: "Portfólio",
    blog: "Blog",
    contact: "Contato",
    login: "Acesso Clientes",
  },
  home: {
    hero: {
      title: "Olá, eu sou Luis Febro",
      subtitle: "Desenvolvedor de Software",
      description: "Resolvendo problemas com tecnologia na web, mobile e backend.",
      cta: "Fale Comigo",
      projects: "Ver projetos",
    },
    social: {
      github: "Acesse meu GitHub",
      googleplay: "Acesse meu perfil de desenvolvedor no Google Play",
      linkedin: "Conecte-se no LinkedIn",
      email: "Envie um email",
      stackoverflow: "Acesse meu perfil no Stack Overflow"
    }
  },
  about: {
    title: "Sobre Mim",
    description: "Engenheiro de software profissional com experiência em...",
    downloadCV: "Baixar Currículo",
    downloadCVTitle: "Baixe meu currículo atualizado",
    skills: {
      title: "Habilidades",
      frontend: "Desenvolvimento Frontend",
      backend: "Desenvolvimento Backend",
      mobile: "Desenvolvimento Mobile",
      architecture: "Arquitetura de Software",
      uiux: "Design de Interface"
    },
    journey: "Minha Jornada",
    experience: {
      title: "Experiência",
      positions: {
        fullstack: {
          title: "Desenvolvedor Mobile, FullStack",
          company: "Febro",
          period: "Jun. 2023 - Presente",
          description: "Aplicativo educacional para aprender novo vocabulário em inglês a partir de uma coleção de tópicos de interesse do usuário com revisão espaçada. Também desenvolvendo um aplicativo Android com foco em scanners (Código de Barras e OCR) e impressão de etiquetas personalizadas diretamente do aplicativo com impressoras Brother."
        },
        mobile: {
          title: "Desenvolvedor de Aplicativos Móveis",
          company: "Offshore Link Sat",
          period: "Jun. 2022 - Mar. 2024",
          description: "Criei um aplicativo móvel nativo em Kotlin e Java para distribuir acesso à internet para algumas escolas públicas para atender às demandas do governo de Maricá, RJ. Também desenvolvi um aplicativo para registro e escaneamento de chips para call centers e um MVP de aplicativo para disparar alertas SOS."
        },
        freelance: {
          title: "Freelancer",
          company: "Autônomo",
          period: "Ago. 2014 - Jan. 2023",
          description: "Alguns dos projetos em destaque incluem: Sistema e Aplicativo para distribuição de internet e painel administrativo com mapa de status e conexões. Criei uma startup martech para sistema de pontos de fidelidade com moeda digital. Desenvolvi um bot financeiro algorítmico para vender e comprar bitcoins diretamente da corretora Novadax usando análise técnica como padrões de candlestick e sentimentos de notícias.\nSistema de pontos de fidelidade web para salão de beleza. Painel e aplicativo. Uma biblioteca escrita com stack MERN para amostragem de livros populares."
        }
      }
    },
    technologies: "Technologias",
    bio: {
      part1: "Com mais de 10 anos de experiência em desenvolvimento de software, trabalhei em diversos projetos incluindo aplicações web, serviços backend, cloud e aplicativos móveis. Sou dedicado em criar soluções limpas, eficientes e amigáveis ao usuário.",
      part2: "Mantenho constantemente atualizado com as últimas tecnologias e melhores práticas da indústria, garantindo a entrega de soluções modernas e escaláveis."
    },
  },
  portfolio: {
    title: "Meus Projetos em Destaque",
    stacks: "Stacks:",
    techs: "Tecnologias:",
    links: {
      code: "Código",
      liveDemo: "Demo ao Vivo",
      playStore: "Play Store",
      site: "Site",
      docs: "Documentação"
    },
    descriptions: {
      mtcCollab: "Um aplicativo em produção com dois modos de escaneamento (OCR e Código de Barras) focado no gerenciamento e verificação de cartões SIM, utilizado por agentes de campo e administradores para gerenciar inventário de dispositivos móveis e registros de funcionários.",
      mtcConnect: "Aplicativo desenvolvido em Kotlin que fornece um gateway para conectividade de internet móvel com um simchip exclusivo. Este é um aplicativo white-label que gerencia diferentes personalizações através de um painel separado.",
      shopClone: "ShopClone é uma demonstração de interface moderna de e-commerce construída com React, TypeScript e Tailwind CSS. Demonstra um design limpo e responsivo para navegação e compra de produtos eletrônicos.",
      scribeScan: "Aplicativo Android focado em atender operadoras de telecomunicações que precisam escanear com segurança usando modelos de IA, salvar e gerenciar cartões SIM, com recursos de escaneamento de código de barras e OCR.",
      sempreAlerta: "Aplicativo Android e painel para gerenciar alertas SOS em tempo real com opções para registrar contatos pessoais e de autoridade para ser avisado quando uma emergência estiver acontecendo. Os alertes incluem e-mail, Whatsapp, SMS, notificações de aplicativo e chamadas de assistente",
      fiddelize: "Um software de programa de fidelidade para recompensar clientes com pontos digitais (PTS) e cardápio digital que podem ser trocados por benefícios e vantagens",
      amurreto: "Bot de trading algorítmico para operar compra e venda quase 100% independente com uma corretora brasileira",
      vocariza: "(Em desenvolvimento) Vocariza é um aplicativo Android que ajuda os usuários a construir seu vocabulário em Inglês através de conteúdo personalizado e um método exclusivo de repetição espaçada baseado em retenção para um aprendizado eficaz. O único objetivo é vencer a curva do esquecimento e ajudar os usuários a lembrarem do vocabulário para o longo prazo. É isso! Os usuários saberão em tempo real exatamente qual é sua retenção atual para assuntos e cada item de vocabulário que estão aprendendo.",
    }
  },
  contact: {
    title: "Contato",
    nameLabel: "Nome",
    emailLabel: "Email",
    messageLabel: "Mensagem",
    sendButton: "Enviar Mensagem",
    sending: 'Enviando mensagem...',
    success: "Mensagem enviada com sucesso! Te contato logo em breve.",
    error: "Erro ao enviar sua mensagem.",
  },
  footer: {
    rights: "Todos os direitos reservados.",
    downloadCV: "Baixar Currículo",
    version: "versão"
  },
  common: {
    comingSoon: "Em Breve",
    stayTuned: "Fique ligado para novidades!"
  },
  blog: {
    title: "Blog - Luis Febro",
    description: "Leia os últimos artigos sobre desenvolvimento de software, insights de tecnologia e dicas de programação."
  },
  clientAccess: {
    title: "Acesso Cliente - Luis Febro",
    description: "Portal seguro para clientes acessarem informações e recursos de seus projetos."
  }
}; 