import ComingSoon from '@/components/shared/ComingSoon';
import SEO from '@/components/shared/SEOHead';
import { useLanguage } from '@/providers/LanguageProvider';

const ClientAccess = () => {
  const { t } = useLanguage();

  return (
    <>
      <SEO 
        title={t('clientAccess.title')}
        description={t('clientAccess.description')}
        keywords="client access, client portal, login"
      />
      <ComingSoon />
    </>
  );
};

export default ClientAccess; 