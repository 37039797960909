export const en = {
  nav: {
    home: "Home",
    about: "About",
    portfolio: "Portfolio",
    blog: "Blog",
    contact: "Contact",
    login: "Clients Access",
  },
  home: {
    hero: {
      title: "Hi, I'm Luis Febro",
      subtitle: "Software Developer",
      description: "Solving problems with technology in the web, mobile and backend.",
      cta: "Get in touch",
      projects: "See projects",
    },
    social: {
      github: "Visit my GitHub profile",
      googleplay: "Visit my Google Play Developer profile",
      linkedin: "Connect with me on LinkedIn",
      email: "Send me an email",
      stackoverflow: "Visit my Stack Overflow profile"
    }
  },
  about: {
    title: "About Me",
    description: "Professional software engineer with experience in...",
    downloadCV: "Download Resume",
    downloadCVTitle: "Download my updated CV",
    skills: {
      title: "Skills",
      frontend: "Frontend Development",
      backend: "Backend Development",
      mobile: "Mobile Development",
      architecture: "Architecture Designs",
      uiux: "UI/UX Design"
    },
    journey: "My Journey",
    experience: {
      title: "Experience",
      positions: {
        fullstack: {
          title: "Mobile, FullStack Developer",
          company: "Febro",
          period: "Jun. 2023 - Present",
          description: "Educational app to learn new vocabulary in English from a collection of topics of interest to the user with spaced review. Also developing an Android app with a focus on scanners (Barcode and OCR) and printing custom labels directly from the app with Brother printers."
        },
        mobile: {
          title: "Mobile Application Developer",
          company: "Offshore Link Sat",
          period: "Jun. 2022 - Nov. 2023",
          description: "Created a native mobile app in Kotlin and Java to distribute internet access to some public schools to meet the demands of the government of Maricá, RJ. Also developed an app for registering and scanning chips for call centers and an MVP app for triggering SOS alerts."
        },
        freelance: {
          title: "Freelancing",
          company: "Self Employed",
          period: "Aug. 2014 - Jan. 2023",
          description: "Some of the highlighted projects include: System and App for internet distribution and admin panel with status and connections map. Created a martech startup for a loyalty points system with digital currency. Developed an algorithmic financial bot to sell and buy bitcoins directly from the Novadax brokerage using technical analysis such as candlestick patterns and news sentiments.\nWeb loyalty points system for a beauty salon. Panel and app. A library written with MERN stack for sampling popular books."
        }
      }
    },
    technologies: "Technologies",
    bio: {
      part1: "With over 10 years of experience in software development, I've worked on various projects including web applications, backend services, cloud and mobile apps. I'm dedicated about creating clean, efficient, and user-friendly solutions.",
      part2: "I constantly stay updated with the latest technologies and best practices in the industry, ensuring that I deliver modern and scalable solutions."
    }
  },
  portfolio: {
    title: "My Featured Projects",
    stacks: "Stacks:",
    techs: "Techs:",
    links: {
      code: "Code",
      liveDemo: "Live Demo",
      playStore: "Play Store",
      site: "Site",
      docs: "Documentation"
    },
    descriptions: {
      mtcCollab: "A production application featuring dual scanning modes (OCR and Barcode) that focuses on SIM card management and verification, used by field agents and administrators to manage mobile device inventory and staff registrations.",
      mtcConnect: "App developed in Kotlin that provides a gateway for a mobile internet connectivity with an exclusive simchip. This is a white-label powered app that handles different customizations via a separated dashboard.",
      shopClone: "ShopClone is a demo modern e-commerce user interface built with React, TypeScript, and Tailwind CSS. It demonstrates a clean and responsive design for browsing and purchasing electronic products.",
      scribeScan: "Android App focused on serving telecom operators who need to securely scan with AI models, save, and manage SIM cards, featuring barcode and OCR scanning capabilities.",
      sempreAlerta: "Android App  & Dashboard to manage SOS alerts in real time with options to record personal and authority contacts to be warned when an emergy is going on. The alerts include e-email, Whatsapp, SMS, app notifications and assistant calls",
      fiddelize: "A loyalty program software to reward customers with digital points (PTS) and digital menu that can be exchanged for benefits and perks",
      amurreto: "Algorithmic trading bot to operate almost 100% independent buying and selling with a brazilian brokerage firm",
      vocariza: "(In development) Vocariza is an Android application that helps users build their English vocabulary through personalized content and an exclusive spaced repetition method based on retention for effective learning. The only goal is to beat the forgetting curve and help users remember vocabulary for the long-term. That's it! Users will know in real-time exactly what their current retention is for subjects and each vocabulary they are learning.",
    }
  },
  contact: {
    title: "Get in Touch",
    nameLabel: "Name",
    emailLabel: "Email",
    messageLabel: "Message",
    sendButton: "Send Message",
    sending: 'Sending message...',
    success: "Message sent successfully! I'll contact you soon.",
    error: "There was an error sending your message.",
  },
  footer: {
    rights: "All rights reserved.",
    downloadCV: "Download Resume",
    version: "version"
  },
  common: {
    comingSoon: "Coming Soon",
    stayTuned: "Stay tuned for exciting updates!"
  },
  blog: {
    title: "Blog - Luis Febro",
    description: "Read the latest articles about software development, tech insights, and coding tips."
  },
  clientAccess: {
    title: "Client Access - Luis Febro",
    description: "Secure portal for clients to access their project information and resources."
  }
}; 