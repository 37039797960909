import { motion } from 'framer-motion';
import { GithubIcon, LinkedinIcon, MailIcon, ArrowRightIcon } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useLanguage } from "@/providers/LanguageProvider";
import SEO from '@/components/shared/SEOHead';

const Home = () => {
  const { t } = useLanguage();

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };

  return (
    <>
      <SEO 
        title="Luis Febro - Software Developer | Home"
        description="Professional software developer specializing in web, mobile, and backend development. Creating efficient and scalable solutions."
        keywords="software engineer, web developer, mobile developer, React, TypeScript, Kotlin"
      />
      <motion.div 
        variants={container}
        initial="hidden"
        animate="show"
        className="min-h-[calc(100vh-4rem)] flex flex-col justify-center items-center py-20 relative"
      >
        <motion.div variants={item} className="relative">
          <img 
            src="/logo/logo-febro_large_background.svg" 
            alt="Profile"
            className="w-32 h-32 rounded-full border-4 border-primary mb-8 bg-black"
          />
          <div className="absolute bottom-6 right-0 bg-primary text-white p-2 rounded-full">
            <span className="block w-4 h-4 rounded-full bg-green-400" />
          </div>
        </motion.div>

        <motion.h2 variants={item} className="text-lg text-primary font-semibold">
          {t('home.hero.title')}
        </motion.h2>

        <motion.h1 variants={item} className="text-4xl md:text-6xl font-bold text-center mt-4 mb-6">
          {t('home.hero.subtitle')}
        </motion.h1>

        <motion.p variants={item} className="text-xl text-gray-600 dark:text-gray-400 text-center max-w-2xl mb-8">
          {t('home.hero.description')}
        </motion.p>

        <motion.div variants={item} className="flex gap-4 mb-12">
          <Link 
            to="/contact"
            className="px-6 py-3 bg-primary text-white rounded-lg hover:bg-primary/90 transition-colors flex items-center gap-2"
          >
            {t('home.hero.cta')}
            <ArrowRightIcon size={20} />
          </Link>
          <Link 
            to="/portfolio"
            className="px-6 py-3 border border-primary text-primary rounded-lg hover:bg-primary/10 transition-colors"
          >
            {t('home.hero.projects')}
          </Link>
        </motion.div>
      </motion.div>
    </>
  );
};

export default Home; 