import ComingSoon from '@/components/shared/ComingSoon';
import SEO from '@/components/shared/SEOHead';
import { useLanguage } from '@/providers/LanguageProvider';

const Blog = () => {
  const { t } = useLanguage();

  return (
    <>
      <SEO 
        title={t('blog.title')}
        description={t('blog.description')}
        keywords="blog, articles, tech blog, programming blog"
      />
      <ComingSoon />
    </>
  );
};

export default Blog; 