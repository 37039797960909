import { useLanguage } from "@/providers/LanguageProvider";
import DownloadCVButton from '@/components/buttons/DownloadCVButton';

const Footer = () => {
    const { t } = useLanguage();
  
    return (
      <footer className="w-full py-3">
        <div className="max-w-7xl mx-auto px-4">
          {/* Subtle horizontal delimiter */}
          <div className="w-full h-px bg-gray-200 dark:bg-gray-800 mb-6" />
          
          <div className="flex flex-col items-center justify-center gap-4">
            <div className="flex flex-wrap justify-center gap-2 sm:gap-6">
              <a 
                href="https://github.com/luisFebro" 
                target="_blank" 
                rel="noopener noreferrer"
                className="p-2 sm:p-2 hover:text-primary transition-colors"
                aria-label={t('home.social.github')}
                title={t('home.social.github')}
              >
                <img 
                  src="/imgs/icons/github.svg" 
                  className="w-7 h-7 sm:w-6 sm:h-6" 
                  alt="GitHub"
                />
              </a>
              <a 
                href="https://play.google.com/store/apps/dev?id=5086066092978840461" 
                target="_blank" 
                rel="noopener noreferrer"
                className="p-2 hover:text-primary transition-colors"
                aria-label={t('home.social.googleplay')}
                title={t('home.social.googleplay')}
              >
                <img 
                  src="/imgs/icons/google_play.svg" 
                  className="w-6 h-6" 
                  alt="Google Play"
                />
              </a>
              <a 
                href="https://stackoverflow.com/users/10010404/luis-febro"
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 hover:text-primary transition-colors"
                aria-label={t('home.social.stackoverflow')}
                title={t('home.social.stackoverflow')}
              >
                <img 
                  src="/imgs/icons/stackoverflow.svg" 
                  className="w-6 h-6" 
                  alt="Stack Overflow"
                />
              </a>
              <a 
                href="https://linkedin.com/in/luisfebro" 
                target="_blank" 
                rel="noopener noreferrer"
                className="p-2 hover:text-primary transition-colors"
                aria-label={t('home.social.linkedin')}
                title={t('home.social.linkedin')}
              >
                <img 
                  src="/imgs/icons/linkedin.svg" 
                  className="w-6 h-6" 
                  alt="LinkedIn"
                />
              </a>
              <a 
                href="mailto:contato"
                className="p-2 hover:text-primary transition-colors"
                aria-label={t('home.social.email')}
                title={t('home.social.email')}
              >
                <img 
                  src="/imgs/icons/email.png" 
                  className="w-6 h-6" 
                  alt="Email"
                />
              </a>
              <DownloadCVButton 
                title={t('about.downloadCVTitle')}
                aria-label={t('about.downloadCVTitle')}
              />
            </div>
            <p className="text-xs sm:text-sm text-gray-600 dark:text-gray-400 text-center">
              © {new Date().getFullYear()} Luis Febro. {t('footer.rights')} • {t('footer.version')} {import.meta.env.PACKAGE_VERSION}
            </p>
          </div>
        </div>
      </footer>
    );
};

export default Footer; 