import { motion } from 'framer-motion';
import { useLanguage } from '@/providers/LanguageProvider';

const ComingSoon = () => {
  const { t } = useLanguage();

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="flex flex-col items-center justify-center min-h-[calc(100vh-4rem)] p-4"
    >
      <h1 className="text-4xl font-bold text-primary mb-4">
        {t('common.comingSoon')}
      </h1>
      <p className="text-gray-600 dark:text-gray-400 text-center">
        {t('common.stayTuned')}
      </p>
    </motion.div>
  );
};

export default ComingSoon; 