import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from './providers/ThemeProvider';
import { LanguageProvider } from '@/providers/LanguageProvider';
import { HelmetProvider } from 'react-helmet-async';
import Layout from './components/layout/Layout';
import Routers from './routes/Routers';

import { Toaster } from 'sonner'

function App() {
  return (
    <>
      <Toaster richColors position="top-right" />
      <HelmetProvider>
        <LanguageProvider>
          <ThemeProvider>
            <Router>
              <Layout>
                <Routers />
              </Layout>
            </Router>
          </ThemeProvider>
        </LanguageProvider>
      </HelmetProvider>
    </>
  );
}

export default App;
