import { useState } from 'react'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { Badge } from '@/components/ui/badge'
import { 
  Github, 
  ExternalLink, 
  Globe, 
  Smartphone, 
  Server, 
  Layers, 
  LucideSmartphone,
  FileText,
  ZoomIn,
  X
} from 'lucide-react'
import { Separator } from "@/components/ui/separator"
import { useLanguage } from '@/providers/LanguageProvider'
import { motion } from 'framer-motion';
import SEO from '@/components/shared/SEOHead';
import { TechNameTooltip } from '@/components/TechNameTooltip'
import { Dialog, DialogContent, DialogOverlay, DialogClose } from "@/components/ui/dialog"

interface Project {
  id: number
  title: string
  descriptionKey: string
  technologies: string[]
  categories: ('web' | 'native mobile' | 'backend' | 'fullstack' | 'PWA mobile')[]
  githubUrl: string | null
  liveUrl?: string | null
  playStoreUrl?: string | null
  siteUrl?: string | null
  imageUrl: string
  documentationUrl?: string | null
}

const Portfolio = () => {
  const { t } = useLanguage();

  const categoryIcons = {
    web: <Globe className="w-4 h-4" />,
    ['native mobile']: <Smartphone className="w-4 h-4" />,
    [`PWA mobile`]: <LucideSmartphone className="w-4 h-4" />,
    backend: <Server className="w-4 h-4" />,
    fullstack: <Layers className="w-4 h-4" />
  }

  const [projects] = useState<Project[]>([
    // https://console.cloudinary.com/pm/c-5f2824fa50100436731e43e387231c/media-explorer/PROJECTS/Scribe%20Scan?assetId=c2726c4903a8eb7296dd3c00a0048b16
    // , , Firebase Cloud Messaging (), AndroidX, Gradle, MVVM Architecture, Google Play Services, , Gson, ProGuard, Git, Android Location Services, Android Notifications API, ViewBinding, Android Navigation Components
    {
      id: 8,
      title: "Vocariza",
      descriptionKey: "vocariza",
      categories: ["native mobile", "backend",  "web"],
      technologies: ["MVVM", "MVC", "Kotlin", "Jetpack Compose", "AWS cloud", "Android SDK",  "Node.js", "RoomDB", "MongoDB", "Generative AI", "SQL", "Express", "React", "Typescript", "Tailwind CSS", "Shadcn/ui"],
      githubUrl: null,
      liveUrl: null,
      playStoreUrl: null,
      siteUrl: null,
      imageUrl: "/portfolio/vocariza.png",
      documentationUrl: null,
    },
    {
      id: 7,
      title: "MTC Connect",
      descriptionKey: "mtcConnect",
      categories: ["native mobile"],
      technologies: ["Kotlin", "View XML", "Android SDK", "FCM", "Volley"],
      githubUrl: null,
      liveUrl: null,
      playStoreUrl: null,
      siteUrl: null,
      imageUrl: "/portfolio/mtc_connect.jpeg",
      documentationUrl: null,
    },
    {
      id: 6,
      title: "MTC Collab",
      descriptionKey: "mtcCollab",
      categories: ["native mobile"],
      technologies: ["Java", "Android SDK", "View XML", "OCR", "ZXing", "Volley"],
      githubUrl: null,
      liveUrl: null,
      playStoreUrl: null,
      siteUrl: null,
      imageUrl: "/portfolio/mtc_collab.png",
      documentationUrl: null,
    },
    {
      id: 5,
      title: "Amurreto",
      descriptionKey: "amurreto",
      categories: ["backend"],
      technologies: ["MERN", "Node.js", "Express", "Javascript", "MongoDB"],
      githubUrl: "https://github.com/luisFebro/amurreto-backend",
      liveUrl: null,
      playStoreUrl: null,
      siteUrl: null,
      imageUrl: "/portfolio/amurreto.png",
      documentationUrl: null,
    },
    {
      id: 4,
      title: "Scribe Scan",
      descriptionKey: "scribeScan",
      categories: ["native mobile", "backend"],
      technologies: ["MVVM", "Kotlin", "Jetpack Compose", "Android SDK", "RoomDB", "Node.js", "MongoDB", "Express", "Brothers API", "IoT", "OCR", "Retrofit"],
      githubUrl: null,
      liveUrl: "https://res.cloudinary.com/febroprojects/video/upload/v1707171982/PROJECTS/Scribe%20Scan/impressora_imprimindo_comando_app.mp4",
      playStoreUrl: "https://play.google.com/store/apps/details?id=com.febro.scribescanapp",
      siteUrl: null,
      imageUrl: "/portfolio/scribe_scan.jpg",
      documentationUrl: "https://www.canva.com/design/DAF5m9XhP1Y/kURj0cfel8ZmHK0lF1vUqg/view?utm_content=DAF5m9XhP1Y&utm_campaign=designshare&utm_medium=link&utm_source=editor",
    },
    {
      id: 3,
      title: "Sempre Alerta SOS",
      descriptionKey: "sempreAlerta",
      categories: ["web", "backend", "native mobile"],
      technologies: ["MVVM", "Kotlin", "View XML", "RoomDB", "Android SDK", "React", "Javascript", "Tailwind CSS", "MongoDB", "Node.js", "Express", "Socket.io", "SSML", "TTS", "IVR", "Volley", "Google Maps"],
      githubUrl: "https://github.com/luisFebro/sempre_alerta_frontend_oficial",
      liveUrl: "https://res.cloudinary.com/febroprojects/video/upload/v1729362185/PROJECTS/sempre_alerta_real_time.mp4",
      playStoreUrl: "https://play.google.com/store/apps/details?id=com.febro.sempre_alerta_official",
      siteUrl: "https://sempre-alerta-frontend-oficial-9i9w56voi-mrfebro.vercel.app/",
      imageUrl: "/portfolio/sempre_alerta.png",
      documentationUrl: null,
    },
    {
      id: 2,
      title: "Fiddelize",
      descriptionKey: "fiddelize",
      categories: ["web", "backend", "PWA mobile"],
      technologies: ["MVC", "MERN", "React", "Javascript", "Sass", "MongoDB", "Node.js", "Express"],
      githubUrl: "https://github.com/luisFebro/fiddelize",
      liveUrl: null,
      playStoreUrl: null,
      siteUrl: "https://fiddelize-main-avdz4zfn4-mrfebro.vercel.app/",
      imageUrl: "/portfolio/fiddelize.png",
      documentationUrl: null,
    },
    {
      id: 1,
      title: "Shop Clone",
      descriptionKey: "shopClone",
      categories: ["web"],
      technologies: ["React", "Vite", "TypeScript", "Tailwind CSS", "Shadcn/ui"],
      githubUrl: "https://github.com/luisFebro/shop_clone",
      liveUrl: null,
      playStoreUrl: null,
      siteUrl: null,
      imageUrl: "/portfolio/shop_clone.png",
      documentationUrl: "https://github.com/luisFebro/shop_clone#readme",
    }
  ])

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const cardVariants = {
    hidden: { 
      opacity: 0,
      y: 20
    },
    visible: { 
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  console.log("SELECTED IMAGE", selectedImage)
  console.log("IS_DIALOG_OPEN", isDialogOpen)

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedImage(null);
  };

  return (
    <>
      <SEO
        title={t('portfolio.title')}
        description={t('portfolio.descriptions')}
        keywords={[
          'portfolio',
          'web development',
          'mobile development',
          'full stack',
          'projects',
          'React',
          'Node.js',
          'Kotlin',
          'MongoDB'
        ].join(', ')}
      />
      <div className="container mx-auto py-12 px-4">
        <motion.h1 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-4xl font-bold mb-8"
        >
          {t('portfolio.title')}
        </motion.h1>
        
        <motion.div 
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
        >
          {projects.map((project) => (
            <motion.div
              key={project.id}
              variants={cardVariants}
            >
              <Card className="hover:shadow-lg transition-shadow h-full">
                <CardHeader>
                  <CardTitle className="mb-4 text-primary">#{project.id} {project.title}</CardTitle>
                  <div className="flex flex-col gap-2">
                    <span className="text-sm text-muted-foreground">{t('portfolio.stacks')}</span>
                    <div className="flex flex-wrap gap-2 mb-4">
                      {project.categories.map((category) => (
                        <Badge key={category} variant="outline" className="flex items-center gap-2">
                          {categoryIcons[category]}
                          <span className="capitalize">{category}</span>
                        </Badge>
                      ))}
                    </div>
                  </div>
                  <div className="relative group">
                    <img 
                      src={project.imageUrl} 
                      alt={project.title} 
                      className="rounded-t-lg w-full aspect-video object-contain bg-background cursor-zoom-in transition-transform duration-300 hover:scale-105"
                      onClick={() => {
                        setSelectedImage(project.imageUrl);
                        setIsDialogOpen(true);
                      }}
                    />  
                  </div>
                  <CardDescription className="mt-4">
                    {t(`portfolio.descriptions.${project.descriptionKey}`)}
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <div className="mb-4">
                    <span className="text-sm text-muted-foreground mb-2">{t('portfolio.techs')}</span>
                    <div className="flex flex-wrap gap-2 mt-1">
                      {project.technologies.map((tech) => (
                        <Badge key={tech} variant="secondary">
                          <TechNameTooltip 
                            techName={tech}
                            className="cursor-help"
                          />
                        </Badge>
                      ))}
                    </div>
                  </div>
                  
                  <Separator className="my-4" />
                  
                  <div className="flex flex-wrap gap-4 pt-2">
                    {project.githubUrl && (
                      <a
                        href={project.githubUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center gap-2 hover:text-primary"
                      >
                        <Github size={20} />
                        <span>{t('portfolio.links.code')}</span>
                      </a>
                    )}
                    {project.liveUrl && (
                      <a
                        href={project.liveUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center gap-2 hover:text-primary"
                      >
                        <ExternalLink size={20} />
                        <span>{t('portfolio.links.liveDemo')}</span>
                      </a>
                    )}
                    {project.playStoreUrl && (
                      <a
                        href={project.playStoreUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center gap-2 hover:text-primary"
                      >
                        <img src="/imgs/icons/google_play_alt.svg" className="w-6 h-6 brightness-50 dark:brightness-200" alt="mobile" />
                        <span>{t('portfolio.links.playStore')}</span>
                      </a>
                    )}
                    {project.siteUrl && (
                      <a
                        href={project.siteUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center gap-2 hover:text-primary"
                      >
                        <Globe size={20} />
                        <span>{t('portfolio.links.site')}</span>
                      </a>
                    )}
                    {project.documentationUrl && (
                      <a
                        href={project.documentationUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center gap-2 hover:text-primary"
                      >
                        <FileText size={20} />
                        <span>{t('portfolio.links.docs')}</span>
                      </a>
                    )}
                  </div>
                </CardContent>
              </Card>
            </motion.div>
          ))}
        </motion.div>
      </div>
      <Dialog open={isDialogOpen} onOpenChange={handleCloseDialog}>
        <DialogContent className="max-w-[90vw] max-h-[90vh] p-0 border-none bg-transparent">
          {selectedImage && (
            <div className="relative w-full h-full flex items-center justify-center">
              <img 
                src={selectedImage} 
                alt="Project preview" 
                className="max-w-full max-h-[90vh] object-contain rounded-lg"
              />
              <DialogClose 
                className="absolute top-2 right-2 p-2 bg-background/80 rounded-full hover:bg-background"
                onClick={handleCloseDialog}
              >
                <X className="h-4 w-4" />
                <span className="sr-only">Close</span>
              </DialogClose>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default Portfolio 